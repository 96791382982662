<template>
    <div>
        <v-toolbar style="background:#cad9e5;">
            <v-row>
                <v-btn
                    tile
                    color=""
                    style="margin-right:1%;"
                    @click="getDatas"
                    :disabled="cancelDisabled==false"
                >
                    <v-icon left>
                        mdi-magnify
                    </v-icon>
                    검색
                </v-btn>
                <v-btn
                    tile
                    color="primary"
                    style="margin-right:1%;"
                    :disabled="cancelDisabled==false"
                >
                    <v-icon left>
                        mdi-plus-circle
                    </v-icon>
                    추가
                </v-btn>
                <v-btn
                    tile
                    color="success"
                    style="margin-right:1%;"
                    :disabled="editDisabled"
                    @click="dialogUserEdit=true"
                >
                    <v-icon left>
                        mdi-pencil
                    </v-icon>
                    수정
                </v-btn>
                <v-btn
                    tile
                    color="error"
                    style="margin-right:1%;"
                    :disabled="deleteDisabled"
                    @click="dialogDelete=true"
                >
                    <v-icon left>
                        mdi-delete
                    </v-icon>
                    삭제
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    tile
                    color="#CDDC39"
                    style="margin-right:1%;"
                    :disabled="pwdDisabled"
                    @click="dialogPwd=true"
                >
                    <v-icon left>
                        mdi-lock-reset
                    </v-icon>
                    비밀번호 변경
                </v-btn>
            </v-row>
        </v-toolbar>
        <v-toolbar style="background:#cad9e5;">
            <v-toolbar-items hidden="false"
                style="margin-right:1%; margin-top:3%;"
            >
                <v-text-field
                    id="text-user_id"
                    label="사용자ID"
                    @change="value => onTextChangne('user_id', value)"
                    @input="onTextChangne('user_id', $event)"
                    @keyup="getDatas"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items
                style="margin-right:1%; margin-top:3%;"
            >
                <v-text-field
                    id="text-user_name"
                    label="이름"
                    @change="value => onTextChangne('user_name', value)"
                    @input="onTextChangne('user_name', $event)"
                    @keyup="getDatas"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items
                style="margin-right:1%; margin-top:3%;"
            >
                <v-text-field
                    id="text-email_address"
                    label="이메일"
                    @change="value => onTextChangne('email_address', value)"
                    @input="onTextChangne('email_address', $event)"
                    @keyup="getDatas"
                ></v-text-field>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="datas"
            class="elevation-1"
            show-select
            item-key="id"
            @click:row="onClickRow"
            height="100%"
            fixed-header
            dense
            single-select
            
            :items-per-page="perPage"
            :footer-props="footerProps"
            :page.sync="page"
            :server-items-length="dataCounts"
            :options.sync="options"
        >
        </v-data-table>

        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-dialog
            v-model="dialogUser"
            width='70%'
            height="45%"
            max-width="1000px"
            style="height: 45%;"
            :scrollable="false"
        >
            <div style="width:100%; height:100%; background:white;">
                <v-container style="width:100%; height:100%;">
                    <v-row style="width:100%; height:80%;">
                        <v-col cols="6">
                            <v-text-field
                                v-model="dialog_user_id"
                                label="사용자ID"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="dialog_password"
                                label="비밀번호"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="dialog_user_name"
                                label="이름"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="dialog_u_type"
                                label="부서"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="dialog_u_position"
                                label="직책"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="dialog_tel_no"
                                label="전화번호"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="dialog_email_address"
                                label="이메일"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row style="width:100%; height:20%;">
                        <v-col cols="6" align="center">
                            <v-btn
                                tile
                                color="primary"
                                @click="addUser"
                            >등록</v-btn>
                        </v-col>
                        <v-col cols="6" align="center">
                            <v-btn
                                tile
                                color="error"
                                @click="dialogUser = false"
                            >취소</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-dialog>

        <v-dialog
            v-model="dialogUserEdit"
            width='70%'
            height="45%"
            max-width="1000px"
            style="height: 45%;"
            :scrollable="false"
        >
            <div style="width:100%; height:100%; background:white;">
                <v-container style="width:100%; height:100%;">
                    <v-row style="width:100%; height:80%;">
                        <v-col cols="6">
                            <v-text-field
                                v-model="dialogEdit_user_id"
                                label="사용자ID"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="dialogEdit_user_name"
                                label="이름"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="dialogEdit_u_type"
                                label="부서"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="dialogEdit_u_position"
                                label="직책"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="dialogEdit_tel_no"
                                label="전화번호"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="dialogEdit_email_address"
                                label="이메일"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row style="width:100%; height:20%;">
                        <v-col cols="6" align="center">
                            <v-btn
                                tile
                                color="primary"
                                @click="editUser"
                            >수정</v-btn>
                        </v-col>
                        <v-col cols="6" align="center">
                            <v-btn
                                tile
                                color="error"
                                @click="dialogUserEdit = false"
                            >취소</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-dialog>

        <v-dialog
            v-model="dialogDelete"
            width="300px"
            max-width="300px"
        >
            <v-card style="height: 180px;">
                <v-card-title>삭제</v-card-title>
                <v-card-text>선택하신 항목을 삭제하시겠습니까?</v-card-text>
                <v-card-actions style="justify-content: end;">
                    <v-btn
                        color="orange"
                        text
                        @click="deleteUser"
                    >확인</v-btn>
                    <v-btn
                        color="orange"
                        text
                        @click="dialogDelete = false"
                    >취소</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogPwd"
            width='70%'
            max-width="1000px"
            :scrollable="false"
        >   
            <div style="width:100%; height:100%; background:white;">
                <v-container>
                    <v-form>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="cur_pwd"
                                    type="password"
                                    autocomplete="off"
                                    label="기존 비밀번호"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="new_pwd"
                                    type="password"
                                    autocomplete="off"
                                    label="신규 비밀번호"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="new_confirm_pwd"
                                    type="password"
                                    autocomplete="off"
                                    label="신규 비밀번호 확인"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" align="center">
                                <v-btn
                                    tile
                                    color="success"
                                    @click="resetPwd"
                                >초기화</v-btn>
                            </v-col>
                            <v-col cols="4" align="center">
                                <v-btn
                                    tile
                                    color="primary"
                                    @click="changePwd"
                                >확인</v-btn>
                            </v-col>
                            <v-col cols="4" align="center">
                                <v-btn
                                    tile
                                    color="error"
                                    @click="dialogPwd = false"
                                >취소</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </div>
        </v-dialog>

        <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
            top
        >
            {{ snackText }}
        </v-snackbar>
    </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "UserMgmt",
  components: {
    
  },
  data() {
    return {
        vUSER_ID:null,

        headers: [
            { value:'user_id', text:'사용자ID', width:'15%', align:'start', sortable: true, },
            { value:'u_type', text:'부서', width:'15%', align:'start', sortable: true, },
            { value:'user_name', text:'이름', width:'25%', align:'start', sortable: true, },
            { value:'u_position', text:'직책', width:'10%', align:'start', sortable: true, },
            { value:'tel_no', text:'전화번호', width:'10%', align:'start', sortable: true, },
            { value:'email_address', text:'이메일', width:'25%', align:'start', sortable: true, },
        ],
        datas:[],
        dataCounts:0,
        perPage:100,
        page:1,
        footerProps: { 'items-per-page-options': [100, -1] },
        options:{},
        params:{},
        selected:[],

        orderBy:null,
        order_Desc:null,

        overlay: false,
        editDisabled:true,
        deleteDisabled:true,
        pwdDisabled:true,

        dialogUser:false,
        dialogUserEdit:false,
        dialogDelete:false,
        dialogPwd:false,

        dialog_user_id:null,
        dialog_password:null,
        dialog_user_name:null,
        dialog_u_type:null,
        dialog_u_position:null,
        dialog_tel_no:null,
        dialog_email_address:null,

        dialogEdit_user_uid:null,
        dialogEdit_user_id:null,
        dialogEdit_user_name:null,
        dialogEdit_u_type:null,
        dialogEdit_u_position:null,
        dialogEdit_tel_no:null,
        dialogEdit_email_address:null,

        cur_pwd:null,
        new_pwd:null,
        new_confirm_pwd:null,
        
        snack:false,
        snackColor:'',
        snackText:'',
        cancelDisabled:true
    };
  },
  mounted() {
    this.init();
  },
  watch: {
      selected() {
          if(this.selected.length>0) {
              if(this.vUSER_ID!=null && (this.vUSER_ID=='admin' || this.vUSER_ID=='root')) {
                this.editDisabled = false
                this.pwdDisabled = false
                this.cancelDisabled = false

                const row_user_id = this.selected[0].user_id;
                if(row_user_id!=null && (row_user_id=='admin' || row_user_id=='root')) {
                    this.deleteDisabled = true
                    this.cancelDisabled = true
                } else {
                    this.deleteDisabled = false
                    this.cancelDisabled = false
                }
              } else {
                const row_user_id = this.selected[0].user_id;
                if(this.vUSER_ID!=null && this.vUSER_ID == row_user_id) {
                    this.editDisabled = false
                    this.pwdDisabled = false
                    this.cancelDisabled = false
                } else {
                    this.editDisabled = true
                    this.pwdDisabled = true
                    this.cancelDisabled = true
                }
              }
          } else {
              this.editDisabled = true
              this.deleteDisabled = true
              this.pwdDisabled = true
              this.cancelDisabled = true
          }
      },

      dialogUser() {
          if(this.dialogUser) {
              this.resetDialog()
          }
      },

      dialogUserEdit() {
          if(this.dialogUserEdit) {
              const select = this.selected[0]
              this.dialogEdit_user_uid = select.unique_id
              this.dialogEdit_user_id = select.user_id
              this.dialogEdit_user_name = select.user_name
              this.dialogEdit_u_type = select.u_type
              this.dialogEdit_u_position = select.u_position
              this.dialogEdit_tel_no = select.tel_no
              this.dialogEdit_email_address = select.email_address
          } else {
              this.resetDialogEdit()
          }
      },

      dialogPwd() {
          if(this.dialogPwd) {
              this.cur_pwd = null
              this.new_pwd = null
              this.new_confirm_pwd = null
          }
      },
      
     options(val) {
        var curPage = val.page;
        var itemsPerPage = val.itemsPerPage;
        var s;
        if(itemsPerPage!=null && itemsPerPage==-1) {
            itemsPerPage = 100000000;
        }
        this.page = curPage;
        this.perPage = itemsPerPage;

        if(val.sortBy[0]){
            s = val.sortDesc[0];
            this.orderBy = "&orderBy=" + val.sortBy[0];
            this.order_Desc = "&isAsc=" + s;
        }
        else{
            this.orderBy="&orderBy=user_name";
            this.order_Desc="&isAsc=true";
        }

        this.getDatas();
     }
  },
  methods: {
    init() {
        this.vUSER_ID = this.$store.getters.getUserId;
        this.getDatas()
    },
    getDatas() {
        var url = this.$vBACKEND_URL + '/index/main.do?method=readUser';
        var start = (this.page-1) * this.perPage;
        var limit = this.perPage;
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          start:start,
          limit:limit,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        if(this.params!=null) {
            for(var k in this.params) {
                params += '&' + k + '=' + this.params[k]
            }
        }
        url += params;
        url += this.orderBy;
        url += this.order_Desc;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const datas = res.datas;
              const count = res.count;
              this.datas = datas;
              this.dataCounts = count;
          })
          .catch(err => {console.log(err)})
    },
    onClickRow(record, row) {
        const isSelected = row.isSelected;
        if(isSelected) {
            row.select(false);
        } else {
            row.select(true);
        }
    },

    onTextChangne(key, val) {
        if(val==null || val.length<1) {
            this.params[key] = '';
        } else {
            this.params[key] = val;
        }
    },

    addUser() {
        if(this.dialog_user_id==null || this.dialog_user_id.length<1) {
            this.snack = true
            this.snackColor = 'red'
            this.snackText = '사용자ID 를 확인해주세요'
            return
        }
        if(this.dialog_password==null || this.dialog_password.length<1) {
            this.snack = true
            this.snackColor = 'red'
            this.snackText = '비밀번호 를 확인해주세요'
            return
        }
        if(this.dialog_user_name==null || this.dialog_user_name.length<1) {
            this.snack = true
            this.snackColor = 'red'
            this.snackText = '이름을 확인해주세요'
            return
        }

        var url = this.$vBACKEND_URL + '/index/main.do?method=addUser';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,

            add_user_id:this.dialog_user_id,
            add_user_pw:this.dialog_password,
            add_user_name:this.dialog_user_name,
            add_u_type:this.dialog_u_type,
            add_u_position:this.dialog_u_position,
            add_tel_no:this.dialog_tel_no,
            add_email_address:this.dialog_email_address,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const result = res.result;
              let color = 'green';
              if(result == 'SUCCESS') {
                  color = 'green'
              } else {
                  color = 'red'
              }
              this.snack = true
              this.snackColor = color
              this.snackText = result

              this.resetDialog()
              this.getDatas()
          })
          .catch(err => {
              console.log(err)
              
              this.snack = true
              this.snackColor = 'red'
              this.snackText = '등록 실패'
          })
    },

    editUser() {
        if(this.dialogEdit_user_uid==null || this.dialogEdit_user_uid<1) {
            this.snack = true
            this.snackColor = 'red'
            this.snackText = '선택한 값을 확인해주세요'
            return
        }
        if(this.dialogEdit_user_id==null || this.dialogEdit_user_id.length<1) {
            this.snack = true
            this.snackColor = 'red'
            this.snackText = '사용자ID 를 확인해주세요'
            return
        }
        if(this.dialogEdit_user_name==null || this.dialogEdit_user_name.length<1) {
            this.snack = true
            this.snackColor = 'red'
            this.snackText = '이름을 확인해주세요'
            return
        }

        var url = this.$vBACKEND_URL + '/index/main.do?method=editUser';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,

            edit_user_uid:this.dialogEdit_user_uid,
            edit_user_name:this.dialogEdit_user_name,
            edit_u_type:this.dialogEdit_u_type,
            edit_u_position:this.dialogEdit_u_position,
            edit_tel_no:this.dialogEdit_tel_no,
            edit_email_address:this.dialogEdit_email_address,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const result = res.result;
              let color = 'green';
              if(result == 'SUCCESS') {
                  color = 'green'
              } else {
                  color = 'red'
              }
              this.snack = true
              this.snackColor = color
              this.snackText = result

              this.dialogUserEdit = false

              this.resetDialog()
              this.getDatas()
          })
          .catch(err => {
              console.log(err)
              
              this.snack = true
              this.snackColor = 'red'
              this.snackText = '등록 실패'
          })
    },

    deleteUser() {
        const selects = this.selected;
        if(selects==null || selects.length<1) {
            this.snack = true
            this.snackColor = 'red'
            this.snackText = '선택하신 값을 확인해주세요'
            return null;
        }

        let unique_ids = [];
        for(var i=0; i<selects.length; i++) {
            var select = selects[i];
            var uid = select.unique_id;
            unique_ids.push(uid);
        }

        var url = this.$vBACKEND_URL + '/index/main.do?method=deleteUser';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,
            unique_id_list:unique_ids
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const result = res.result;
              let color = 'green';
              if(result == 'SUCCESS') {
                  color = 'green'
              } else {
                  color = 'red'
              }
              this.snack = true
              this.snackColor = color
              this.snackText = result

              this.dialogDelete = false;

              this.getDatas()
          })
          .catch(err => {
              console.log(err)
              
              this.snack = true
              this.snackColor = 'red'
              this.snackText = '삭제 실패'
          })
    },

    resetDialog() {
        this.dialog_user_id = null
        this.dialog_password = null
        this.dialog_user_name = null
        this.dialog_u_type = null
        this.dialog_u_position = null
        this.dialog_tel_no = null
        this.dialog_email_address = null
    },
    resetDialogEdit() {
        this.dialogEdit_user_id = null
        this.dialogEdit_user_name = null
        this.dialogEdit_u_type = null
        this.dialogEdit_u_position = null
        this.dialogEdit_tel_no = null
        this.dialogEdit_email_address = null
    },

    changePwd() {
        if(this.cur_pwd==null || this.cur_pwd.length<1) {
            this.snack = true
            this.snackColor = "red"
            this.snackText = "기존 비밀번호를 확인해주세요."
            return
        }
        if(this.new_pwd==null || this.new_pwd.length<1) {
            this.snack = true
            this.snackColor = "red"
            this.snackText = "신규 비밀번호를 확인해주세요."
            return
        }
        if(this.new_pwd!=null && (this.new_pwd != this.new_confirm_pwd)) {
            this.snack = true
            this.snackColor = "red"
            this.snackText = "신규 비밀번호와 동일하지 않습니다."
            return
        }

        var url = this.$vBACKEND_URL + '/index/main.do?method=changePwd';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,
            
            unique_id:this.selected[0].unique_id,
            cur_pwd:this.cur_pwd,
            password:this.new_pwd
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const result = res.result;
              let color = 'green';
              if(result == 'SUCCESS') {
                  color = 'green'
                  
                  this.dialogPwd = false;
                  this.getDatas()
              } else {
                  color = 'red'
              }
              this.snack = true
              this.snackColor = color
              this.snackText = result
          })
          .catch(err => {
              console.log(err)
              
              this.snack = true
              this.snackColor = 'red'
              this.snackText = '비밀번호 변경 실패'
          })
    },

    resetPwd() {
        var url = this.$vBACKEND_URL + '/index/main.do?method=resetPwd';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,
            
            unique_id:this.selected[0].unique_id,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              let result = res.result;
              let color = 'green';
              if(result == 'SUCCESS') {
                  color = 'green'
                  result = '비밀번호가 0000 으로 초기화 되었습니다.'
              } else {
                  color = 'red'
              }
              this.snack = true
              this.snackColor = color
              this.snackText = result

              this.dialogPwd = false;

              this.getDatas()
          })
          .catch(err => {
              console.log(err)
              
              this.snack = true
              this.snackColor = 'red'
              this.snackText = '비밀번호 초기화 실패'
          })
    }

  }
};
</script>

<style>
.v-data-table__wrapper {
    height:72vh !important;
}
</style>